import type {
  Dataset,
  FileInfo,
  GalleryFile,
  Parameter,
  ProcessedDataset,
  ProcessedFileList,
} from './api-models';
import { JOIN_CHAR, JOIN_IN_CHAR, OPERATORS } from './Filters/router-utils';
import { assertDefined, assertEnvVar, assertNonNull } from './guards';
import { CATALOG_UNIT } from './unit-catalog';

const GLOBUS_ROOT = import.meta.env.VITE_GLOBUS_ROOT;

export function processDatasetResponse(
  datasetResponse: Dataset,
): ProcessedDataset {
  const parametersTmp: Parameter[] = datasetResponse.parameters || [];
  const parameters = new Map(parametersTmp.map((p) => [p.name, p]));

  // Not splitting only with " " because sometimes, several whitespaces
  // can separate two ids, so splitting with the \s+ regex
  const galleryIds =
    parameters.get('ResourcesGallery')?.value.split(/\s+/u) || [];

  const gallery: GalleryFile[] =
    parameters
      .get('ResourcesGalleryFilePaths')
      ?.value.split(',')
      .map((path, index) => ({
        id: galleryIds[index],
        name: path.slice(path.lastIndexOf('/') + 1),
        type: /\.(png|jpg)$/iu.test(path)
          ? 'image'
          : /\.(mp4)$/iu.test(path)
          ? 'video'
          : 'unknown',
      })) || [];

  return { ...datasetResponse, parameters, galleryIds, gallery };
}

export function processFileListResponse(
  fileListResponse: FileInfo[],
): ProcessedFileList {
  const files = (fileListResponse || [])
    .filter(
      (f) =>
        !f.Datafile.name.startsWith('/gallery/') &&
        !f.Datafile.name.startsWith('/pictures/') &&
        // Filters defined for paleo data where some unwanted files
        // were ingested by mistakes
        // Might be removed in the future if the paths are removed
        // from ICAT
        !f.Datafile.name.startsWith('/trash') &&
        f.Datafile.name.includes('.'),
    )
    .map((f) => ({
      id: f.Datafile.id,
      datasetId: f.Datafile.dataset.id,
      fileSize: f.Datafile.fileSize,
      name: f.Datafile.name,
      location: f.Datafile.location,
      ext: f.Datafile.name
        .slice(f.Datafile.name.lastIndexOf('.') + 1)
        .toUpperCase(),
      shortName: f.Datafile.name.slice(1, f.Datafile.name.lastIndexOf('.')),
    }));

  return {
    files,
  };
}

export function getMetadataByName(
  parameters: Map<string, Parameter>,
  name: string,
): string {
  const metadata = parameters.get(name);
  if (metadata === undefined) {
    return 'unknown';
  }
  assertDefined(metadata);
  const value = metadata.value.toString();
  if (value === ' ') {
    return 'unknown';
  }

  if (metadata.name in CATALOG_UNIT) {
    return `${value} ${CATALOG_UNIT[metadata.name.toString()]}`;
  }
  return value;
}

export function dateFormat(date: string) {
  if (date.includes('T')) {
    const [shortDate] = date.split('T');
    const [year, month, day] = shortDate.split('-');
    return `${day}/${month}/${year}`;
  }
  return date;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getGlobusUrl(filePath: string) {
  assertEnvVar(GLOBUS_ROOT, 'VITE_GLOBUS_ROOT');
  const globusPath = filePath.replace(GLOBUS_ROOT, '');
  const params = new URLSearchParams({
    origin_id: import.meta.env.VITE_GLOBUS_ORIGIN_ID,
    origin_path: globusPath.slice(0, globusPath.lastIndexOf('/')),
  });

  return `https://app.globus.org/file-manager?${params.toString()}`;
}

export function sampleNameSplitter(sampleName: string, patientName: string) {
  const formattedSampleName = sampleName.replaceAll('_', '-');
  const formattedPatientName = patientName.replaceAll('_', '-');
  const sampleInfo = formattedSampleName.split(`${formattedPatientName}-`)[1];
  const [organ, position] = sampleInfo.split('-').slice(0, 2);

  return position ? `${organ}-${position}` : organ;
}

export function sampleNameFormatter(sampleName: string, patientName: string) {
  const splittedSampleName = sampleNameSplitter(sampleName, patientName);
  const [organ, position] = splittedSampleName.split('-');
  return capitalizeFirstLetter(organLeftRight(organ, position));
}

export function organLeftRight(organ: string, position: string) {
  return position === 'left' || position === 'right'
    ? `${position} ${organ}`
    : organ;
}

export function queryParamsToAPIParams(
  queryParams: Record<string, string> | undefined,
) {
  let APIParams = '';

  if (queryParams) {
    for (const [queryKey, queryValue] of Object.entries(queryParams)) {
      if (queryValue.startsWith('glt~')) {
        const range = queryValue.split(JOIN_CHAR)[1];
        const values = range.split(JOIN_IN_CHAR).map(Number);
        APIParams += `,${queryKey}~${OPERATORS.gteq}${values[0]}`;
        APIParams += `,${queryKey}~${OPERATORS.lteq}${values[1]}`;
      } else {
        APIParams += `,${queryKey}~${queryValue}`;
      }
    }
  }
  return APIParams;
}

export function trackLink(evt: React.MouseEvent<HTMLAnchorElement>) {
  window._paq?.push(['trackLink', evt.currentTarget.href, 'link']);
}

export function trackDownload(evt: React.MouseEvent<HTMLAnchorElement>) {
  const { currentTarget: target } = evt;
  const isGlobusLink = target.href.includes('globus');

  const label = isGlobusLink ? target.href : target.getAttribute('download');
  assertNonNull(label);
  window._paq?.push(['trackLink', label, 'download']);
}

export function trackPlay(videoSrc: string) {
  window._paq?.push(['trackEvent', 'Video', 'Play', videoSrc]);
}
