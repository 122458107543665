import { Helmet } from 'react-helmet';

import styles from './NeuroglancerTutorial.module.css';

function NeuroglancerTutorial() {
  return (
    <>
      <Helmet title="Tutorial - Neuro" />

      <div className={styles.videoContainer}>
        <h1 className={styles.tutorialTitle}>Guide to Neuroglancer</h1>
        <iframe
          className={styles.videoplayer}
          width="560"
          height="315"
          src="https://www.youtube.com/embed/Yg0ITKw5GiE?si=PVqTHgRy4ewo4D8k"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        />
      </div>
    </>
  );
}

export default NeuroglancerTutorial;
