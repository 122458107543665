import { assertNonNull } from 'lib/guards';
import { useLocation } from 'wouter';

import styles from './ToCFiji.module.css';

function ToCFiji() {
  const [, navigate] = useLocation();

  function handleSectionLinkClick(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const to = event.currentTarget.getAttribute('href');
    assertNonNull(to);
    navigate(`/fiji${to}`);
    document.querySelector(to)?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <ul className={styles.tocList}>
      <li>
        <a
          className={styles.inlineLink}
          href="#preamble"
          onClick={handleSectionLinkClick}
        >
          Preamble
        </a>
      </li>
      <li>
        <a
          className={styles.inlineLink}
          href="#downloadFiji"
          onClick={handleSectionLinkClick}
        >
          Download FIJI/ImageJ
        </a>
      </li>
      <li>
        <a
          className={styles.inlineLink}
          href="#gettingStarted"
          onClick={handleSectionLinkClick}
        >
          Getting started
        </a>
      </li>
      <li>
        <a
          className={styles.inlineLink}
          href="#exampleMacro"
          onClick={handleSectionLinkClick}
        >
          Small Macro Example with BoneJ
        </a>
      </li>
    </ul>
  );
}

export default ToCFiji;
