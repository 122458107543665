import type { GroupFilter } from 'lib/filters-models';

export const FILTERS_BY_GROUP: GroupFilter[] = [
  {
    groupLabel: 'Patient',
    filters: [
      {
        name: 'SamplePatient_sex',
        label: 'Sex',
        type: 'radios',
      },
      {
        name: 'SamplePatient_age',
        label: 'Age',
        type: 'range',
        hideUnit: true,
      },
      {
        name: 'SamplePatient_size',
        label: 'Height',
        type: 'range',
      },
      {
        name: 'SamplePatient_weight',
        label: 'Weight',
        type: 'range',
      },
    ],
  },
  {
    groupLabel: 'Sample',
    filters: [
      {
        name: 'SamplePatient_organ_name',
        label: 'Organ',
        type: 'checkboxes',
      },
      {
        name: 'SamplePatient_info',
        label: 'Pathology',
        type: 'text',
        placeholder: 'covid, pneumopathy, etc.',
      },
    ],
  },
  {
    groupLabel: 'Scan parameters',
    filters: [
      {
        name: 'TOMO_pixelSize',
        label: 'Pixel size',
        type: 'range',
        step: 1 / 100,
      },
      {
        name: 'TOMO_energy',
        label: 'Detected avg. energy',
        type: 'range',
      },
      {
        name: 'TOMO_surface_dose',
        label: 'Surface dose rate',
        type: 'range',
        step: 1 / 10,
      },
      {
        name: 'TOMO_total_voi_dose',
        label: 'VOI integ. dose',
        type: 'range',
        step: 1 / 100,
      },
    ],
  },
];
