import type { ReactNode } from 'react';

import styles from './MetaItem.module.css';

function MetaItem(props: {
  label: string;
  wide?: boolean;
  narrow?: boolean;
  children: ReactNode;
}) {
  const { label, children, wide, narrow } = props;
  return (
    <li className={styles.item} data-narrow={narrow || undefined}>
      {wide ? (
        <div
          className={styles.label}
          data-wide
          data-narrow={narrow || undefined}
        >
          {label}
        </div>
      ) : (
        <div className={styles.label} data-narrow={narrow || undefined}>
          {label}
        </div>
      )}

      <div className={styles.metadata} data-narrow={narrow || undefined}>
        {children}
      </div>
    </li>
  );
}

export default MetaItem;
