import { Link } from 'wouter';

import styles from './ToC.module.css';

function ToC() {
  return (
    <div className={styles.tocContainer}>
      <div>
        <Link className={styles.inlineLink} to="/fiji">
          Fiji tutorial
        </Link>
      </div>
      <div>
        <Link className={styles.inlineLink} to="/neuro">
          Neuroglancer tutorial
        </Link>
      </div>
    </div>
  );
}

export default ToC;
