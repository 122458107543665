import byteSize from 'byte-size';
import type { PropsWithChildren } from 'react';
import { FiDownload } from 'react-icons/fi';

import { useIcatSessionId } from '../api-hooks';
import type { ProcessedFile } from '../api-models';
import { trackLink } from '../helpers';
import styles from './Files.module.css';
import GlobusLink from './GlobusLink';

interface Props {
  doi: string;
  files: ProcessedFile[];
  handleClick?: (evt: React.MouseEvent<HTMLAnchorElement>) => void;
}

const LICENSE_NAME = import.meta.env.VITE_LICENSE_NAME;
const LICENSE_URL = import.meta.env.VITE_LICENSE_URL;

function Files(props: PropsWithChildren<Props>) {
  const { doi, files, handleClick, children } = props;
  const globusFilePath = files[0].location;

  const sessionId = useIcatSessionId();

  return (
    <div>
      <div className={styles.fileSection}>
        {children}

        {files.length > 1 && (
          <GlobusLink filePath={globusFilePath} handleClick={handleClick} />
        )}

        {[...files]
          .sort((a, b) => b.fileSize - a.fileSize)
          .map((f) => (
            <a
              key={f.id}
              className={styles.downloadFile}
              data-alt
              href={`https://ids.esrf.fr/ids/getData?sessionId=${sessionId}&datafileIds=${f.id}`}
              download={`${f.shortName}.${f.ext.toLowerCase()}`}
              target="_blank"
              rel="noreferrer"
              onClick={handleClick}
            >
              <span>{f.ext}</span>
              <span>
                <span>
                  <span>{f.shortName} </span>
                  <span className={styles.sizeByte}>
                    &mdash; {byteSize(f.fileSize).toString()}
                  </span>
                </span>
              </span>
              <span>
                <FiDownload />
              </span>
            </a>
          ))}
      </div>

      <p className={styles.dataPolicy}>
        These datasets are made freely available under the{' '}
        <a
          className={styles.infoLink}
          href={LICENSE_URL}
          target="_blank"
          rel="noreferrer"
        >
          {LICENSE_NAME} licence
        </a>
        . Publications using these datasets are required to{' '}
        <a
          className={styles.infoLink}
          href={`http://doi.org/${doi}`}
          target="_blank"
          rel="noreferrer"
          onClick={(evt) => trackLink(evt)}
        >
          cite the&nbsp;DOI
        </a>
        .
      </p>
    </div>
  );
}

export default Files;
