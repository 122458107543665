import { useMediaQuery } from '@react-hookz/web';
import { Boundary } from 'lib/boundary';
import { assertDefined } from 'lib/guards';
import { PageWrapper } from 'lib/layout';
import { Helmet } from 'react-helmet';
import { Route, useLocation } from 'wouter';

import DatasetPage from './Dataset/DatasetPage';
import ExplorePage from './Explore/ExplorePage';
import HelpPage from './Help/HelpPage';
import HomePage from './Home/HomePage';
import Navigation from './Navigation';
// import ReconstructionsPage from './Reconstructions/ReconstructionsPage';
import SearchPage from './Search/SearchPage';
import TutorialPage from './Tutorials/TutorialPage';

function App() {
  const isDesktop = useMediaQuery(`(min-width: 56.25rem)`);

  assertDefined(isDesktop);
  const location = useLocation();

  return (
    <>
      <Helmet
        defaultTitle="Human Organ Atlas"
        titleTemplate="%s - Human Organ Atlas"
      />
      <Navigation isDesktop={isDesktop} />

      <PageWrapper>
        <Boundary resetKeys={[location]}>
          <Route path="/">
            <HomePage />
          </Route>
          <Route path="/explore" nest>
            <ExplorePage />
          </Route>
          <Route path="/search">
            <SearchPage isDesktop={isDesktop} />
          </Route>
          <Route path="/datasets/:datasetId">
            {(params) => (
              <DatasetPage datasetId={params.datasetId} isDesktop={isDesktop} />
            )}
          </Route>
          {/* <Route path="/reconstructions" nest>
            <ReconstructionsPage />
          </Route> */}
          <Route path="/tutorial" nest>
            <TutorialPage isDesktop={isDesktop} />
          </Route>
          <Route path="/help">
            <HelpPage isDesktop={isDesktop} />
          </Route>
        </Boundary>
      </PageWrapper>
    </>
  );
}

export default App;
