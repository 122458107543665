import { NavBar, NavLink } from 'lib/navbar';

interface Props {
  isDesktop: boolean;
}

function Navigation(props: Props) {
  const { isDesktop } = props;

  return (
    <NavBar isDesktop={isDesktop} title="Human Organ Atlas">
      <NavLink to="/explore">Explore</NavLink>
      <NavLink to="/search">Search</NavLink>
      {/* <NavLink to="/reconstructions">
        {isTablet ? `3D Reconstructions` : `3D Reconst.`}
      </NavLink> */}
      <NavLink to="/tutorial">Tutorials</NavLink>
      <NavLink to="/help">Help</NavLink>
    </NavBar>
  );
}

export default Navigation;
