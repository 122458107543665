import RCSlider from 'rc-slider';
import { useState } from 'react';

import { useValuesParameters } from '../api-hooks';
import { assertEnvVar } from '../guards';
import { CATALOG_UNIT } from '../unit-catalog';
import ClearButton from './ClearButton';
import FilterBox from './FilterBox';
import styles from './Range.module.css';
import { JOIN_IN_CHAR, OPERATORS, useQueryParam } from './router-utils';

interface Props {
  name: string;
  label: string | boolean;
  hideUnit?: boolean;
  step?: number;
}

const RangeSlider = RCSlider.createSliderWithTooltip(RCSlider.Range);

const PROJECT_NAME = import.meta.env.VITE_PROJECT_NAME;

function Range(props: Props) {
  const { name, label, hideUnit, step = 1 } = props;

  assertEnvVar(PROJECT_NAME, 'VITE_PROJECT_NAME');
  const valuesParam = useValuesParameters({
    name,
    parameters: `project_name~eq~${PROJECT_NAME}`,
  }).flatMap((p) => p.values.map(Number));

  const values = [...new Set(valuesParam)];

  const min = Math.min(...values);
  const max = Math.max(...values);

  const param = useQueryParam(name);
  const initialValue = param.value
    ? param.value.split(JOIN_IN_CHAR).map(Number)
    : [min, max];

  const [valueRange, setValueRange] = useState(initialValue);

  return (
    <FilterBox
      title={`${label}${hideUnit ? '' : ` (${CATALOG_UNIT[name]})`}`}
      showTitle={label !== false}
      isActive={param.isActive}
    >
      <div className={styles.rangeContainer}>
        <input
          className={styles.rangeCheckbox}
          type="checkbox"
          checked={param.isActive}
          aria-label="Toggle range filter"
          onChange={() => {
            if (param.isActive) {
              param.remove();
            } else {
              param.setValue(
                `${OPERATORS.glt}${valueRange.join(JOIN_IN_CHAR)}`,
              );
            }
          }}
        />
        <div className={styles.rangeSlider}>
          <RangeSlider
            value={valueRange}
            min={min}
            max={max}
            step={step}
            allowCross={false}
            onChange={(val) => {
              setValueRange(val);
            }}
            onAfterChange={(val) => {
              if (initialValue[0] !== val[0] || initialValue[1] !== val[1]) {
                param.setValue(`${OPERATORS.glt}${val.join(JOIN_IN_CHAR)}`);
              }
            }}
          />
        </div>
        <ClearButton
          disabled={!param.isActive}
          onClick={() => {
            setValueRange([min, max]);
            param.remove();
          }}
        />
      </div>
    </FilterBox>
  );
}

export default Range;
