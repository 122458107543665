import wretch from 'wretch';
import QueryStringAddon from 'wretch/addons/queryString';

import type {
  DataCollectionResponse,
  DatasetsResponse,
  FileListResponse,
  IcatSessionResponse,
  InvestigationParameter,
  InvestigationParametersResponse,
  InvestigationResponse,
  ProcessedDataset,
  ProcessedFileList,
  ValuesParameter,
  ValuesParametersResponse,
} from './api-models';
import { assertEnvVar } from './guards';
import {
  processDatasetResponse,
  processFileListResponse,
  queryParamsToAPIParams,
} from './helpers';

const ICATP = import.meta.env.VITE_ICATP;
assertEnvVar(ICATP, 'VITE_ICATP');

const PROJECT_NAME = import.meta.env.VITE_PROJECT_NAME;

const ICAT_SESSION_PARAMS = {
  plugin: 'db',
  username: 'reader',
  password: 'reader',
};

const icatApi = wretch(ICATP).options({ mode: 'cors' }).addon(QueryStringAddon);

export async function fetchIcatSession() {
  const { sessionId } = await icatApi
    .url('/session')
    .post(ICAT_SESSION_PARAMS)
    .json<IcatSessionResponse>();

  return sessionId;
}

export async function fetchInvestigationIds(sessionId: string) {
  assertEnvVar(PROJECT_NAME, 'VITE_PROJECT_NAME');

  const response = await icatApi
    .url(`/catalogue/${sessionId}/investigation`)
    .query({ parameters: `project_name~eq~${PROJECT_NAME}` })
    .get()
    .json<InvestigationResponse>();

  return response.map((i) => i.id.toString()).join(',');
}

export async function fetchAllDatasets(
  sessionId: string,
  investigationIds: string,
  parametersInfo?: Record<string, string>,
): Promise<ProcessedDataset[]> {
  assertEnvVar(PROJECT_NAME, 'VITE_PROJECT_NAME');

  const API_PARAMS = parametersInfo
    ? `project_name~eq~${PROJECT_NAME}${queryParamsToAPIParams(parametersInfo)}`
    : `project_name~eq~${PROJECT_NAME}`;

  const QUERY_PARAMS = {
    investigationIds,
    parameters: API_PARAMS,
  };

  const datasets = await icatApi
    .url(`/catalogue/${sessionId}/dataset`)
    .query(QUERY_PARAMS)
    .get()
    .json<DatasetsResponse>();

  return datasets.map(processDatasetResponse);
}

export async function fetchDatasetsById(
  sessionId: string,
  datasetIds: string[],
): Promise<ProcessedDataset[]> {
  const queryParam = { datasetIds: datasetIds.join(',') };
  const datasets = await icatApi
    .url(`/catalogue/${sessionId}/dataset`)
    .query(queryParam)
    .get()
    .json<DatasetsResponse>();

  return datasets.map(processDatasetResponse);
}

export async function fetchDatasetsByQuery(
  sessionId: string,
  investigationIds: string,
  queryParam: string,
): Promise<ProcessedDataset[]> {
  const queryParams = {
    investigationIds,
    parameters: queryParam,
  };
  const datasets = await icatApi
    .url(`/catalogue/${sessionId}/dataset`)
    .query(queryParams)
    .get()
    .json<DatasetsResponse>();

  return datasets.map(processDatasetResponse);
}

export async function fetchFileList(
  sessionId: string,
  datasetId: string,
): Promise<ProcessedFileList> {
  const response = await icatApi
    .url(`/catalogue/${sessionId}/datafile`)
    .query({ datasetId })
    .get()
    .json<FileListResponse>();

  return processFileListResponse(response);
}

export async function fetchInvestigationParameters(
  sessionId: string,
  investigationIds: string,
  queryParam: Record<string, string>,
): Promise<InvestigationParameter[]> {
  const investigationSeparateIds: string[] = investigationIds.split(',');
  const promiseParam = investigationSeparateIds.map((inv) => {
    return icatApi
      .url(`/catalogue/${sessionId}/datasetparameter`)
      .query({ investigationId: inv, ...queryParam })
      .get()
      .json<InvestigationParametersResponse>();
  });
  const allParam = await Promise.all(promiseParam);
  return allParam.flat();
}

export async function fetchValuesParameters(
  sessionId: string,
  investigationIds: string,
  queryParam: Record<string, string>,
): Promise<ValuesParameter[]> {
  const investigationSeparateIds: string[] = investigationIds.split(',');
  const promiseParam = investigationSeparateIds.map((inv) => {
    return icatApi
      .url(`/catalogue/${sessionId}/datasetparameter/values`)
      .query({
        investigationId: inv,
        ...queryParam,
      })
      .get()
      .json<ValuesParametersResponse>();
  });
  const allParam = await Promise.all(promiseParam);
  return allParam.flat();
}

export async function fetchDOI(
  sessionId: string,
  datasetId: number,
): Promise<string> {
  const [collection] = await icatApi
    .url(`/catalogue/${sessionId}/datacollection`)
    .query({ datasetId })
    .get()
    .json<DataCollectionResponse>();

  return collection.doi;
}

export async function fetchNeuroglancerInfo(
  sessionId: string,
  resourceId: string,
): Promise<string> {
  return icatApi
    .url(`/resource/${sessionId}/file/download`)
    .query({ resourceId })
    .get()
    .text();
}
