import { useValuesParameters } from 'lib/api-hooks';

import PatientCard from './PatientCard';
import styles from './PatientList.module.css';

function PatientList() {
  const patientsAll = useValuesParameters({
    name: 'SamplePatient_number',
  }).flatMap((p) => p.values);

  // This is to filter once again patientsAll. As of now, the endpoint
  // /datasetparameter only takes one investigationId and so a Promise.all is
  // made with all the investigation ids and one patient can be found in
  // several investigations, so the unique=True query parameter will only act
  // on one Promise and a patient may be present several time in the patientsAll
  const patientsUnique = [...new Set(patientsAll)];

  return (
    <div className={styles.patientContainer}>
      {patientsUnique.map((patient) => {
        return <PatientCard key={patient} patient={patient} />;
      })}
    </div>
  );
}

export default PatientList;
