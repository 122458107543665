import { useValuesParameters } from '../api-hooks';
import { assertEnvVar } from '../guards';
import FilterBox from './FilterBox';
import styles from './Pickers.module.css';
import { useListQueryParam } from './router-utils';

interface Props {
  name: string;
  label: string | boolean;
}

const PROJECT_NAME = import.meta.env.VITE_PROJECT_NAME;

function ListPicker(props: Props) {
  const { name, label } = props;
  const param = useListQueryParam(name);

  assertEnvVar(PROJECT_NAME, 'VITE_PROJECT_NAME');
  const valuesParam = useValuesParameters({
    name,
    parameters: `project_name~eq~${PROJECT_NAME}`,
  }).flatMap((p) => p.values);

  const listPicks = [...new Set(valuesParam)].filter((p) => p !== ' ');

  return (
    <FilterBox
      title={label}
      showTitle={label !== false}
      isActive={param.isActive}
      onClear={() => param.remove()}
    >
      <div className={styles.listOption}>
        {listPicks.map((item) => (
          <label className={styles.option} key={item}>
            <input
              className={styles.inputOption}
              type="checkbox"
              checked={param.values.includes(item)}
              name={item}
              aria-label={item}
              onChange={() => param.toggleValue(item)}
            />
            <div className={styles.labelOption}>{item}</div>
          </label>
        ))}
      </div>
    </FilterBox>
  );
}

export default ListPicker;
