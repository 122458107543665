import { useValuesParameters } from 'lib/api-hooks';
import { CATALOG_UNIT } from 'lib/unit-catalog';
import { FiUser } from 'react-icons/fi';
import { Link, useRoute } from 'wouter';

import styles from './PatientCard.module.css';

interface Props {
  patient: string;
}

const PARAMS = {
  sex: 'SamplePatient_sex',
  age: 'SamplePatient_age',
  size: 'SamplePatient_size',
  weight: 'SamplePatient_weight',
  info: 'SamplePatient_info',
};

function PatientCard(props: Props) {
  const { patient } = props;

  const patientInfos = useValuesParameters({
    name: Object.values(PARAMS).join(','),
    parameters: `SamplePatient_number~eq~${patient}`,
  });

  const patientSex = patientInfos.find((p) => p.name === PARAMS.sex);
  const patientAge = patientInfos.find((p) => p.name === PARAMS.age);
  const patientSize = patientInfos.find((p) => p.name === PARAMS.size);
  const patientWeight = patientInfos.find((p) => p.name === PARAMS.weight);
  const patientInfo = patientInfos.find((p) => p.name === PARAMS.info);

  const [isActive] = useRoute(`/${patient}/*?`);

  return (
    <Link
      className={isActive ? styles.activePatientCard : styles.patientCard}
      to={`/${encodeURIComponent(patient)}`}
    >
      <div className={styles.cardTitle}>
        <div className={styles.fiUser}>
          <FiUser />
        </div>
        <h2 className={styles.patientTitle}>{patient}</h2>
      </div>
      <div className={styles.cardInfo} data-keywords>
        {patientSex && (
          <div className={styles.infoPill}>
            {patientSex.values[0]} {CATALOG_UNIT[PARAMS.sex]}
          </div>
        )}
        {patientAge && (
          <div className={styles.infoPill}>
            {patientAge.values[0]} {CATALOG_UNIT[PARAMS.age]}
          </div>
        )}
        {patientWeight && (
          <div className={styles.infoPill}>
            {patientWeight.values[0]} {CATALOG_UNIT[PARAMS.weight]}
          </div>
        )}
        {patientSize && (
          <div className={styles.infoPill}>
            {patientSize.values[0]} {CATALOG_UNIT[PARAMS.size]}
          </div>
        )}
      </div>
      {patientInfo && (
        <p className={styles.patientAbstract}>
          {patientInfo.values[0]} {CATALOG_UNIT[PARAMS.info]}
        </p>
      )}
    </Link>
  );
}

export default PatientCard;
