import type { ProcessedDataset } from 'lib/api-models';
import { assertEnvVar } from 'lib/guards';
import { dateFormat, getMetadataByName, organLeftRight } from 'lib/helpers';
import { Link } from 'wouter';

import styles from './DatasetCard.module.css';

const ICATP = import.meta.env.VITE_ICATP;

function DatasetCard(props: { dataset: ProcessedDataset; sessionId: string }) {
  const { id, parameters, gallery } = props.dataset;
  const { sessionId } = props;

  const url = `~/datasets/${encodeURIComponent(id)}`;
  const thumbnail = gallery.find((f) => f.name.startsWith('thumbnail'));

  assertEnvVar(ICATP, 'VITE_ICATP');

  const organDescription = getMetadataByName(
    parameters,
    'SamplePatient_organ_description',
  ).split('_')[0];
  const organName = getMetadataByName(parameters, 'SamplePatient_organ_name');
  const organInfo = organLeftRight(organName, organDescription);

  return (
    <Link
      className={styles.datasetCard}
      to={url}
      state={{ backLabel: 'results' }}
    >
      <div className={styles.datasetContent}>
        <h2 className={styles.datasetTitle}>
          {getMetadataByName(parameters, 'datasetName')}
        </h2>
        <div className={styles.patientContext}>
          <div className={styles.infoPill}>
            {getMetadataByName(parameters, 'SamplePatient_number')}
          </div>
          <div className={styles.infoPill}>{organInfo}</div>
          <div className={styles.infoPill}>
            {dateFormat(getMetadataByName(parameters, 'startDate'))}
          </div>
        </div>
        <p className={styles.datasetAbstract}>
          {getMetadataByName(parameters, 'DOI_abstract')}
        </p>
      </div>
      <div className={styles.thumbnail}>
        {thumbnail && (
          // Put loading="lazy" before src. See comment in lib/Dataset/Media.tsx
          <img
            alt=""
            loading="lazy"
            src={`${ICATP}/resource/${sessionId}/file/download?resourceId=${thumbnail.id}`}
          />
        )}
      </div>
    </Link>
  );
}

export default DatasetCard;
