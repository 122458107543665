import { trackDownload } from 'lib/helpers';
import { Modal } from 'react-responsive-modal';

import { useCitationStore, useModalStore } from '../stores';
import styles from './ModalDownload.module.css';

function ModalDownload() {
  const { setModalSeen } = useCitationStore();
  const { modalState, setModalState } = useModalStore();

  return (
    <Modal
      center
      blockScroll={false}
      open={modalState.open}
      onClose={() => {
        setModalState({ open: false });
      }}
    >
      <h2 className={styles.modalTitle}>Citation required</h2>
      <p className={styles.intro}>
        When using this data, please cite the following publication and DOI:
      </p>
      <p className={styles.citation}>
        Walsh, C.L., Tafforeau, P., Wagner, W.L. <em>et al.</em> Imaging intact
        human organs with local resolution of cellular structures using
        hierarchical phase-contrast tomography. <em>Nat Methods</em> (2021).
        https://doi.org/10.1038/s41592-021-01317-x
      </p>
      <div className={styles.downloadInfos}>
        <a
          className={styles.downloadLink}
          href={modalState?.url}
          download={modalState?.filename}
          target="_blank"
          rel="noreferrer"
          onClick={(evt) => {
            trackDownload(evt);

            if (modalState.ok) {
              setModalSeen(true);
            }

            setModalState({ ...modalState, open: false });
          }}
        >
          {modalState.url?.includes('globus')
            ? 'Continue to Globus'
            : 'Download file'}
        </a>
        <div className={styles.downloadAgreement}>
          <input
            id="ok"
            className={styles.agreementCheckbox}
            type="checkbox"
            name="download agreement checkbox"
            aria-label="download agreement checkbox"
            onChange={() => {
              setModalState({ ...modalState, ok: true });
            }}
          />
          <label htmlFor="ok" className={styles.agreementLabel}>
            I understand, don't show this again
          </label>
        </div>
      </div>
    </Modal>
  );
}

export default ModalDownload;
