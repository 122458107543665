import { ExploreLayout } from 'lib/explore-layout';
import { usePageTracking } from 'lib/hooks';

import DatasetList from './DatasetList';
import OrganList from './OrganList';
import PatientList from './PatientList';

function ExplorePage() {
  usePageTracking();

  const layerList = {
    firstLayer: {
      title: 'Patients',
      component: <PatientList key="Patients" />,
    },
    secondLayer: { title: 'Organs', component: <OrganList key="Organs" /> },
    thirdLayer: {
      title: 'Datasets',
      component: <DatasetList key="Datasets" />,
    },
  };

  return <ExploreLayout layerList={layerList} />;
}

export default ExplorePage;
