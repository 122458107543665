import { useValuesParameters } from '../api-hooks';
import { assertEnvVar } from '../guards';
import FilterBox from './FilterBox';
import styles from './Pickers.module.css';
import { OPERATORS, useQueryParam } from './router-utils';

interface Props {
  name: string;
  label: string | boolean;
}

const PROJECT_NAME = import.meta.env.VITE_PROJECT_NAME;

function OptionsPicker(props: Props) {
  const { name, label } = props;
  const param = useQueryParam(name);

  assertEnvVar(PROJECT_NAME, 'VITE_PROJECT_NAME');
  const valuesParam = useValuesParameters({
    name,
    parameters: `project_name~eq~${PROJECT_NAME}`,
  }).flatMap((p) => p.values);

  const options = [...new Set(valuesParam)];

  return (
    <FilterBox
      title={label}
      showTitle={label !== false}
      isActive={param.isActive}
      onClear={() => param.remove()}
    >
      <div className={styles.listOption}>
        {options.map((option) => (
          <label className={styles.option} key={option}>
            <input
              className={styles.inputOption}
              type="radio"
              value={option}
              checked={param.value === option}
              name={name}
              aria-label={option}
              onChange={() => param.setValue(`${OPERATORS.eq}${option}`)}
            />
            <div className={styles.labelOption}>{option}</div>
          </label>
        ))}
      </div>
    </FilterBox>
  );
}

export default OptionsPicker;
