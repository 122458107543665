import { usePageTracking, useScrollToTop } from 'lib/hooks';
import type { ReactNode } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useRoute } from 'wouter';

import FijiTutorial from './Fiji/FijiTutorial';
import NeuroglancerTutorial from './NeuroglancerTutorial';
import Toc from './ToC';
import styles from './TutorialPage.module.css';

interface Props {
  isDesktop: boolean;
}

function TutorialPage(props: Props) {
  useScrollToTop();
  usePageTracking();

  const { isDesktop } = props;
  const tutorials: Record<string, ReactNode> = {
    fiji: <FijiTutorial isDesktop={isDesktop} />,
    neuro: <NeuroglancerTutorial />,
  };

  const [, params] = useRoute('/:tutorialName');
  const [, navigate] = useLocation();

  const tutorialName = params?.tutorialName;

  useEffect(() => {
    if (!tutorialName) {
      navigate('/list');
    }
  }, [tutorialName, navigate]);

  return (
    <>
      <Helmet>
        <title>Tutorials</title>
      </Helmet>

      <div className={styles.wrapper}>
        {isDesktop ? (
          <aside className={styles.tocAside}>
            <div className={styles.tocSticky}>
              <h1 className={styles.tocTitle}>Tutorials</h1>
              <Toc />
            </div>
          </aside>
        ) : (
          <details className={styles.tocDetails}>
            <summary className={styles.tocSummary}>Tutorials</summary>
            <div className={styles.tocContent}>
              <Toc />
            </div>
          </details>
        )}
        {tutorialName === 'list' && (
          <div>
            <h2 className={styles.tutorialTitle}>
              Some tutorials to help with processing the datasets
            </h2>
            <ul className={styles.tutorialList}>
              <li>
                <Link className={styles.inlineLink} to="/fiji">
                  Fiji tutorial
                </Link>
                : to visualise the datasets
              </li>
              <li>
                <Link className={styles.inlineLink} to="/neuro">
                  Neuroglancer tutorial
                </Link>
                : to visualise the datasets
              </li>
            </ul>
          </div>
        )}
        {tutorialName && tutorials[tutorialName]}
      </div>
    </>
  );
}

export default TutorialPage;
